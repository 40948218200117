const {
  REACT_APP_IDLE_TIMEOUT,
  REACT_APP_GRAPHQL_HTTP_URI,
  REACT_APP_GRAPHQL_WS_URI,
  REACT_APP_AZURE_BLOB_PREFIX,
  REACT_APP_RHS_URI_BASE,
  REACT_APP_API_HTTP_URI,
  REACT_APP_MIB_REQUEST,
  REACT_APP_PATIENT_SCAN_URL
} = process.env;

export const IDLE_LOGOUT_TIME = REACT_APP_IDLE_TIMEOUT  // 1 Hour in milliseconds

export const WORKFLOW_TYPE = [
  {
    id: "0", label: "",
  },
  {
    id: "1", label: "PKA (Anspach)",
  },
  {
    id: "2", label: "PKA 3.0 (MICS) - burr only",
  },
  {
    id: "3", label: "PKA 3.0 (MICS) - planar (burr+saw)",
  },
  {
    id: "4", label: "THA Enhanced",
  },
  {
    id: "5", label: "THA Express",
  },
]

export const APPLICATION_VERSION = [
  {
    id: "0", label: "",
  },
  {
    id: "1", label: "1.0",
  },
  {
    id: "2", label: "2.0",
  },
  {
    id: "3", label: "2.5",
  },
  {
    id: "4", label: "3.0",
  },
  {
    id: "5", label: "3.1",
  },
  {
    id: "6", label: "4.0",
  },
  {
    id: "7", label: "4.1",
  },
  {
    id: "8", label: "5.0",
  },
]

export const THA_APPROACH = [
  {
    id: "0", label: "",
  },
  {
    id: "1", label: "Direct Anterior",
  },
  {
    id: "2", label: "Direct Superior",
  },
  {
    id: "3", label: "Antero-Lateral",
  },
  {
    id: "4", label: "Postero-Lateral",
  }
]

export const BARCODE_TYPE = {
  Linear: "Linear",
  HIBC: "HIBC",
  Stacked: "Stacked",
  DataMatrix: "DataMatrix",
};

export const GS128_IDENTIFIER = {
  "01": "GTIN",
  11: "productionDate",
  17: "expirationDate",
  10: "lot_number",
  21: "serialNumber",
};

export const GS128_FIELD_LENGTH = {
  GTIN: 14,
  productionDate: 6,
  expirationDate: 6,
  lot_number: 20,
  serialNumber: 20,
};

export const CURRENT_BARCODE_STATE = {
  firstScan: "firstScan",
  SecondScanOverlay: "SecondScanOverlay",
  SecondScan: "SecondScan",
  SecondScanSuccess: "SecondScanSuccess",
};

export const ASYNC_STORAGE = {
  TOKEN: `PU:TOKEN`,
  CACHE: `PU:CACHE`,
  SAVED_CASES: `PU:CASES`,
  CURRENT_USER: "PU:USER",
  PARTS_LAST_SYNC_DATE: "PARTS_LAST_SYNC_DATE",
  access_token: `access_token`,
  persistroot: "persist:root",
};

export const SCREEN_TO_TITLE_MAPPING = {
  Cases: "Surgical Cases",
  Orders: "Orders",
  Calendar: "Calendar",
  SetLibrary: "Set Library",
  GetPrice: "Get Price",
  Preferences: "Surgeon Preferences",
  NewPreferences: "New Surgeon Preferences",
  Notification: "Notification Settings",
  Help: "Help",
};

export const SIDEBAR_MENUS = [
  "Preferences",
  "Notification",
  "GetPrice",
  "Help",
];

export const SETS_PARTS_STATUS = {
  REQUESTED: "Requested",
  ASSIGNED: "Assigned",
  SHIPPED: "Shipped/Ready for Surgery",
  SHIPPED_READY: "Shipped",
};

export const REPSUITE_CASE_STATUS = {
  NEW: "New",
  REQUESTED: "Requested",
  ASSIGNED: "Assigned",
  SHIPPED_READY: "Shipped/Ready for Surgery",
  COMPLETED: "Completed",
  CLOSED: "Closed",
  CANCELLED: "Cancelled",
};

export const TPR_STATUS = {
  CREATED: "Created",
  REJECTED: "Rejected",
  CREATED_AWAITING_CTSCAN: "Created-Awaiting CT scan",
  REJECTED_REWORK_REQUIRED: "Rejected-Rework Required",
  SUBMITTED: "Submitted",
  SIGNED_OFF: "SignedOff",
  CANCELLED: "Cancelled",
};

export const BADGE_STATUS_COLORS = {
  New: "#B83C27",
  Requested: "#CF6E29",
  Assigned: "#FFB500",
  Shipped: "#58B837",
  "Shipped/Ready for Surgery": "#58B837",
  SHIPPED_READY: "#58B837",
  Closed: "#B2B4AE",
  Completed: "#2B72CC",
  Cancelled: "#000000",
  "Provider Community": "#85458A",
  Loaner: "#DDDDDD",
};

export const BADGE_TEXT_COLORS = {
  Loaner: "#FFFFFF",
  Other: "#FFFFFF",
  Assigned: "#FFFFFF",
  Requested: "white",
  New: "white",
  "Shipped/Ready for Surgery": "white",
};

export const CALENDAR_STATUS_COLORS = {
  New: "#B83C27",
  Requested: "#CF6E29",
  Assigned: "#FFB500",
  Shipped: "#58B837",
  "Shipped/Ready for Surgery": "#58B837",
  SHIPPED_READY: "#58B837",
  Closed: "#B2B4AE",
  Completed: "#2B72CC",
  Cancelled: "#000000",
  "Provider Community": "#85458A",
};

export const CALENDAR_STATUS = [
  { id: "All", label: "All", color: "Aqua" },
  { id: "New", label: "New", color: CALENDAR_STATUS_COLORS.New },
  {
    id: "Requested",
    label: "Requested",
    color: CALENDAR_STATUS_COLORS.Requested,
  },
  { id: "Assigned", label: "Assigned", color: CALENDAR_STATUS_COLORS.Assigned },
  {
    id: "Shipped/Ready for Surgery",
    label: "Shipped/Ready for Surgery",
    color: CALENDAR_STATUS_COLORS.Shipped,
  },
  {
    id: "Completed",
    label: "Completed",
    color: CALENDAR_STATUS_COLORS.Completed,
  },
  { id: "Closed", label: "Closed", color: CALENDAR_STATUS_COLORS.Closed },
  {
    id: "Cancelled",
    label: "Cancelled",
    color: CALENDAR_STATUS_COLORS.Cancelled,
  },
];

export const CASE_STATUS = [
  { id: "New", label: "New" },
  { id: "Requested", label: "Requested" },
  { id: "Assigned", label: "Assigned" },
  { id: "Shipped/Ready for Surgery", label: "Shipped/Ready for Surgery" },
  { id: "Completed", label: "Completed" },
  { id: "Closed", label: "Closed" },
  { id: "Cancelled", label: "Cancelled" },
];

// Case status calendar dot colors
export const DOT_COLORS = {
  "#B83C27": 1,
  "#CF6E29": 2,
  "#FFB500": 3,
  "#58B837": 4,
  "#000000": 5,
  "#2B72CC": 6,
  "#B2B4AE": 7,
  "#85458A": 8,
};

export const API = {
  REACT_APP_MIB_REQUEST: REACT_APP_MIB_REQUEST,
  PATIENT_SCANS_PDF: `${REACT_APP_PATIENT_SCAN_URL}/items`,
  REACT_APP_MIB_METADATA_REQUEST: `${REACT_APP_MIB_REQUEST}/patientscans/metadata`,
  GRAPHQL: REACT_APP_GRAPHQL_HTTP_URI,
  REST_URL: `${REACT_APP_GRAPHQL_HTTP_URI.replace('/api', '/rest')}`,
  MODELN_PRICING_URL: `${REACT_APP_GRAPHQL_HTTP_URI.replace('/api', '/rest')}/modelnresolvepricing`,
  MODELN_CONSTRUCT_RESOLVE_URL: `${REACT_APP_GRAPHQL_HTTP_URI.replace('/api', '/rest')}/modelnConstructResolve`,
  MODELN_CONSTRUCT_PRICE_URL: `${REACT_APP_GRAPHQL_HTTP_URI.replace('/api', '/rest')}/modelnConstructPrice`,
  MODELN_PRICE_SAVE_URL: `${REACT_APP_GRAPHQL_HTTP_URI.replace('/api', '/rest')}/modelnDBOperation`,
  MASTER_DATA: {
    CHOICES: `${REACT_APP_GRAPHQL_HTTP_URI.replace("/api", "/rest")}/choices`,
    CREATEORUPDATECASE: `${REACT_APP_API_HTTP_URI}createOrUpdateCase`,
    CANCELCASE: `${REACT_APP_API_HTTP_URI}cancelCase`,
    NOTIFICATIONPOP: `${REACT_APP_GRAPHQL_HTTP_URI.replace("/api", "/rest")}/notificationPopup`,
    TRANSLATIONS: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/translations`,
    INVOICE: `${REACT_APP_GRAPHQL_HTTP_URI.replace("/api", "/rest")}/invoice`,
    HEROKUINVOICE: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/herokuInvoice`,
    EMAIL_SERVICE: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/emailService/sendEmail`,
    UPLOAD_DOCUMENTS: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/imageService`,
    GET_CASE_By_ID: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/cases/getCaseById`,
    PARTS: `${REACT_APP_GRAPHQL_HTTP_URI.replace("/api", "/rest")}/parts`,
    CONSIGNMENT_SETS: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/consignmentSets`,
    JRT_SET_CATALOGS: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/jrtSetCatalogs`,
    JRT_SET_CATALOGS_NONE_STORAGE: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/jrtSetCatalogsNoStorage`,
    LOANER_SETS: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/loanerSets`,
    PICKLISTS: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/picklists`,
    PROCEDURES: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/procedures`,
    SET_CATALOGS: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/setCatalogs`,
    MAKO_MAPPING: `${REACT_APP_GRAPHQL_HTTP_URI.replace(
      "/api",
      "/rest"
    )}/rhsMapping`,
  },
  RHS: {
    TPR_URI: `${REACT_APP_RHS_URI_BASE}/treatmentplanrequests`,
    TREATMENT_PLAN_URI: `${REACT_APP_RHS_URI_BASE}/treatmentplans`,
    TREATMENT_PLAN_METADATA_URI: `${REACT_APP_RHS_URI_BASE}/treatmentplans/metadata`,
    SURGICAL_CASE_ATTACHMENTS_URI: `${REACT_APP_RHS_URI_BASE}/attachments/metadata`,
    PATIENT_URI: `${REACT_APP_RHS_URI_BASE}/patientinfo`,
    LIST_ALL_TREATMENT_PLAN_REQUESTS_URI: `${REACT_APP_RHS_URI_BASE}/treatmentplanrequests`,
    LIST_ALL_SURGICAL_PLANS_ATTACHMENTS: `${REACT_APP_RHS_URI_BASE}/attachments`,
  },
  MIB: {
    PATIENT_SCAN_URI: `${REACT_APP_MIB_REQUEST}/patientscans/`,
    PATIENT_SCAN_METADATA_URI:`${REACT_APP_MIB_REQUEST}/patientscans/metadata`,
  },
};

export const IMAGE_FETCH_URL = `${REACT_APP_AZURE_BLOB_PREFIX}/`;

export const FILE_NAME_PATTERN = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/g;

export const GET_MIME_TYPE = {
  jpg: "image/jpeg",
  png: "image/png",
  pdf: "application/pdf",
};

export const apiToFieldNameOrder = {
  erpNumber: "Billing Order No",
  aroNo: "Replenish Order No",
  PoNumber: "Purchase Order No",
  PoDate: "PO Date",
  Status: "Order Status",
  OrderBookedDate: "Order Date",
  InvoiceDate: "Invoice Date",
  InvoiceNumber: "Invoice No",
  TotalAmount: "Total Order Amount",
  //finalbillamount: 'Total Order Amount'
};

export const CURRENCY_PATTERN = /[^0-9.-]+/g;

export const DIVISIONS = [
  { id: "Joint Replacement", label: "Joint Replacement" },
  { id: "Trauma & Extremeties", label: "Trauma & Extremeties" },
  { id: "Trauma", label: "Trauma" },
];

export const CASES_LIMIT = 20; // Max cases to fetch from api

export const DEFAULT_CASES_QTY = 1; // Default cases quantity to show

// Internal
export const ROLE_CASES = {
  MY_CASES: "My Cases",
  COVERING_CASES: "Covering Cases",
  TEAM_CASES: "Team Cases",
  BRANCH_CASES: "Branch Cases",
};

// Case History Action Tracker
export const CASE_ACTION_TRACKER = {
  CASE_CREATED: 1,

  CASE_UPDATED: 2,

  SETS_REQUESTED: 3,

  STATUS_UPDATED: 4,

  SETS_REMOVED: 5,

  USAGE_UPDATED: 6,

  USAGE_SUBMITTED: 7,

  CASE_COMPLETED: 8,

  CASE_CANCELLED: 9,
};

// Case History Action Tracker Message
export const CASE_ACTION_TRACKER_MSG = {
  CASE_CREATED: "Case Created",
  CASE_UPDATED: "Case Updated",
  SETS_REQUESTED: "Sets Requested",
  STATUS_UPDATED: "Status Updated",
  SETS_REMOVED: "Sets Removed",
  USAGE_UPDATED: "Usage Updated",
  USAGE_SUBMITTED: "Usage Submitted",
  CASE_COMPLETED: "Case Completed",
  CASE_CANCELLED: "Case Cancelled",
};

export const DEFAULT_STATUSES = [
  { id: "AllActive", label: "All Active" },
  { id: "New", label: "New" },
  { id: "Assigned", label: "Assigned" },
  { id: "Requested", label: "Requested" },
  { id: "Shipped/Ready for Surgery", label: "Shipped/Ready for Surgery" },
  { id: "Shipped", label: "Shipped" },
  { id: "Cancelled", label: "Cancelled" },
  { id: "Closed", label: "Closed" },
];

export const GENDER = [
  { id: "male", label: "Male" },
  { id: "female", label: "Female" },
];

export const REPLENISH = [
  { id: "Home", label: "Home" },
  { id: "Branch", label: "Branch" },
  { id: "Hospital", label: "Hospital" },
  { id: "Other", label: "Other" },
];

export const SHIPTO = [
  { id: "Home", label: "Home" },
  { id: "Branch", label: "Branch" },
  { id: "HFPU", label: "HFPU" },
  { id: "Hospital", label: "Hospital" },
  { id: "Other", label: "Other" },
];

export const AS1_STATUSES = [
  { id: "Treatment Plan Available", label: "Surgical Plan Available" },
  { id: "Created-Awaiting CT scan", label: "Created-Awaiting CT scan" },
  {
    id: "Conditionally Approved",
    label: "Surgical Plan Conditionally Approved",
  },
  {
    id: "Final Plan Available",
    label: "Final Plan Available and Product Manufacturing",
  },
  { id: "Shipped To Sales", label: "Shipped To Sales" },
  { id: "Change Requested", label: "Surgical Plan Change Requested" },
  { id: "Created", label: "Created-Awaiting CT scan" },
  { id: "Submitted", label: "Submitted" },
  { id: "InDesign", label: "In Design" },
  { id: "Rejected", label: "Rejected-Rework Required" },
  { id: "TreatmentPlanAvailable", label: "Surgical Plan Available" },
  { id: "Treatment Plan Available", label: "Surgical Plan Available" },
  {
    id: "ConditionallyApproved",
    label: "Surgical Plan Conditionally Approved",
  },
  { id: "ChangeRequested", label: "Surgical Plan Change Requested" },
  { id: "Approved", label: "Surgical Plan Approved" },
  {
    id: "FinalPlanAvailable",
    label: "Final Plan Available and Product Manufacturing",
  },
  { id: "ShippedToSales", label: "Shipped To Sales" },
  { id: "Completed", label: "Completed" },
  { id: "Cancelled", label: "Cancelled" },
];

export const LinkName = {
  CASE_CALENDAR: "CASE CALENDAR",
  SURGICAL_CASES: "SURGICAL CASES",
  NOTIFICATIONS: "NOTIFICATIONS",
  PATIENT_SCANS: "PATIENT SCANS",
  CASE_CARD_VIEW: "SURGICAL CASE CARD VIEW",
  TREATMENT_PLAN_REQUEST: "TREATMENT PLAN REQUEST",
  SURGEON_PREFERENCES: "SURGEON PREFERENCES",
  SUPPORT: "SUPPORT",
};

export const USER_TYPE = {
  MPS: "MPS",
  MAKO_MPS: "Mako MPS",
  SALES_REP: "Sales Rep",
  SALES_ASSOCIATE: "Sales Associate",
  ADMIN: "Business Admin",
  IHS: "IHS & Segementation",
  InHouse: "In-House",
  R_and_D: "R&D",
  CLINICAL_APP_SUPPORT: "Clinical App Support",
};

export const ACCESS_FOR = {
  CASES: "Cases",
  CALENDAR: "Calendar",
};

export const CASE_STORAGE_LIMIT = 5;
export const FETCH_SETS_LOCAL_TIME = 70000;
export const EMAIL_NOTIFICATION_LABEL_INFO = {
  SURGICAL_CASE_NOTIFICATION: {
    SHIP_READY_FOR_SURGERY_SETS_NOT_LABEL:
      "Ship/Ready for Surgery Sets Notification",
    SHIP_READY_FOR_SURGERY_SETS_NOT_INFO:
      "Ship/Ready for Surgery Sets Notification",
    NO_SETS_ON_CASE_NOT_LABEL: "No Sets(s) on Case Notification",
    NO_SETS_ON_CASE_NOT_INFO: "No Sets(s) on Case Notification",
    SEND_EMAIL_INVITE_NOT_LABEL: "Send Email Invite Notification",
    SEND_EMAIL_INVITE_NOT_INFO: "Send Email Invite Notification",
    ORACLE_ORDER_NOTIFY_LABEL: "Oracle Order Notify",
    ORACLE_ORDER_NOTIFY_INFO: "Oracle Order Notify",
  },
  MAKO_TPR_NOTIFICATION: {
    TPR_CREATION_LABEL: "Created",
    TPR_CREATION_INFO: "Created",
    TPR_INDESIGN_LABEL: "In Design",
    TPR_INDESIGN_INFO: "In Design",
    TPR_REJECTION_LABEL: "Rejected",
    TPR_REJECTION_INFO: "Rejected",
    TPR_SUBMISSION_LABEL: "Submitted",
    TPR_SUBMISSION_INFO: "Submitted",
    TREATMENT_PLAN_AVAILABLE_LABEL: "Treatment Plan Available",
    TREATMENT_PLAN_AVAILABLE_INFO: "Treatment Plan Available",
    TREATMENT_PLAN_DOWNLOADED_LABEL: "Treatment Plan Downloaded",
    TREATMENT_PLAN_DOWNLOADED_INFO: "Treatment Plan Downloaded",
    TPR_CANCELLED_LABEL: "Cancelled",
    TPR_CANCELLED_INFO: "Cancelled",
  },
  AS1_TPR_NOTIFICATION: {
    TPR_CREATION_AWAITING_CT_SCAN_LABEL: "Created-Awaiting CT scan:",
    TPR_CREATION_AWAITING_CT_SCAN_INFO: "Created-Awaiting CT scan:",
    TPR_SUBMISSION_LABEL: "Submission",
    TPR_SUBMISSION_INFO: "Submission",
    TPR_SURGICAL_PLAN_CON_APP_LABEL: "Surgical Plan Conditionally Approved",
    TPR_SURGICAL_PLAN_CON_APP_INFO: "Surgical Plan Conditionally Approved",
    TPR_INDESIGN_LABEL: "In Design",
    TPR_INDESIGN_INFO: "In Design",
    TPR_SURGICAL_PLAN_CHANGE_REQ_LABEL: "Surgical Plan Change Requested",
    TPR_SURGICAL_PLAN_CHANGE_REQ_INFO: "Surgical Plan Change Requested",
    TPR_REJECTION_LABEL: "Rejection",
    TPR_REJECTION_INFO: "Rejection",
    TPR_SURGICAL_PLAN_APPROVED_LABEL: "Surgical Plan Approved",
    TPR_SURGICAL_PLAN_APPROVED_INFO: "Surgical Plan Approved",
    TPR_COMPLETED_LABEL: "Completed",
    TPR_COMPLETED_INFO: "Completed",
    TPR_CANCELLED_LABEL: "Cancelled",
    TPR_CANCELLED_INFO: "Cancelled",
  },
};

export const TPR_REJECTION_REASONS = {
  Triathlon_Total_Knee_Options: [
    "Ankle slice increment/spacing violation",
    "Bilat in one exam",
    "Failed motion check",
    "Femur not optimal length for planning",
    "Field-of-view (FOV) violation",
    "File compression issue",
    "Hip slice increment/spacing violation",
    "Image blur",
    "Knee slice increment/spacing violation",
    "Missing region(s) - ankle not included",
    "Missing region(s) - hip not included",
    "Missing region(s) - knee not included",
    "Motion rod not in view",
    "Open brace error",
    "Partial knee / PF already present",
    "Severely rotated",
    "Tibia not optimal length for planning",
    "Unmatched reference frames",
  ],
  Total_Hip_Options: [
    "1 hip, 1 knee",
    "ASIS points missing - full pelvis not in view",
    "Existing THA implant",
    "Femur not optimal length for planning",
    "Fused anatomy",
    "Field-of-view (FOV) violation",
    "File compression issue",
    "Hip slice increment/spacing violation",
    "Incorrect naming convention",
    "Incorrect # of files/images",
    "Knee slice increment/spacing violation",
    "Missing region(s) - pelvis not included",
    "Missing region(s) - knees not included",
    "No contrast",
    "Open brace error",
    "Resolution matrix less than 512x512 or not square",
    "Scans broken up in planning laptop",
    "Special characters in file name(s)",
    "Unmatched reference frames",
  ],
  MCK_Partial_Knee_Options: [
    "Ankle slice increment/spacing violation",
    "Bilat in one exam",
    "Failed motion check",
    "Femur not optimal length for planning",
    "Field-of-view (FOV) violation",
    "File compression issue",
    "Hip slice increment/spacing violation",
    "Image blur",
    "Knee slice increment/spacing violation",
    "Missing region(s) - ankle not included",
    "Missing region(s) - hip not included",
    "Missing region(s) - knee not included",
    "Motion rod not in view",
    "Open brace error",
    "Partial knee / PF already present",
    "Severely rotated",
    "Tibia not optimal length for planning",
    "Unmatched reference frames"
  ]
}

export const BUS_SEG_CODE = [
  "Orthopaedics Oracle Primary",
  "Orthopaedics Oracle Wasted",
  "Orthopaedics Oracle Revision",
  "Orthopaedics Oracle Revision Wasted"
]

export const QQ_CODE_OPTIONS = [
  {
    value: 1,
    label: 'EQUITY PURCHASE'
  },
  {
    value: 2,
    label: 'EVALUATION PRODUCT'
  },
  {
    value: 3,
    label: 'INVENTORY DISCOUNT AGREEMENT'
  },
  {
    value: 4,
    label: 'NEGOTIATED CONTRACT/PROMOTION'
  },
  {
    value: 5,
    label: 'NEGOTIATED SET SALE'
  },
  {
    value: 6,
    label: 'PRODUCT EXCHANGE'
  },
  {
    value: 7,
    label: 'PRODUCT UPGRADE'
  },
  {
    value: 8,
    label: 'WASTED/OPEN IN ERROR CUSTOMER'
  },
  {
    value: 9,
    label: 'WASTED/OPEN IN ERROR STRYKER'
  }
]

export const PROCEDURE_TYPE = "Orthopaedics Oracle";

export const operationCaseStatus = {
  createOrUpdateTPR: 'createOrUpdateTPR',
  setWebCaseProducts: 'setWebCaseProducts',
  setCapPrices: 'setCapPrices',
  updatePartsUsed: 'updatePartsUsed',
  setContractPrices: 'setContractPrices',
  addImages: 'addImages',
  deleteImages: 'deleteImages',
  createOrUpdateMics: 'createOrUpdateMics',
  updateSurgerySheet: 'updateSurgerySheet',
  createOrUpdatePrefrences: 'createOrUpdatePrefrences',
  trackPatientScan: 'trackPatientScan',
  deletePatientScans: 'deletePatientScans',
  createOrUpdateCase: 'createOrUpdateCase',
  createOrUpdateMPSTracker: 'createOrUpdateMPSTracker',
}