//import gql from 'graphql-tag';
import moment from "moment";
import { uniq, isEmpty } from "lodash";
import GraphQLClient from "./client";
import { SETS_PARTS_STATUS } from "../constants";
import { gql, useMutation } from "@apollo/client";

export async function updateSet({ payload }) {
  const mutation = gql`
    mutation updateSet($sets: [SetInput]) {
      updateSet(sets: $sets)
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: { sets: payload },
  });
}

export async function deletePatientScans(payload) {
  const mutation = gql`
    mutation deletePatientScans(
      $scans: [ScansInput]
      $tpr_sfid: String
      $case_sfid: String
    ) {
      deletePatientScans(
        scans: $scans
        tpr_sfid: $tpr_sfid
        case_sfid: $case_sfid
      ) {
        scan_rhs_id
        scan_sfid
        external_id
        scan_name
        upload_by
        upload_date
        active
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: { ...payload },
  });
}

export async function setCaseProducts(variables) {
  const mutation = gql`
    mutation setCaseProducts(
      $case__c: String
      $externalId: String
      $is_hospital_owned: Boolean
      $sets: [CaseProductSetInput]
      $parts: [CaseProductPartInput]
      $division: String
      $is_submit: Boolean
      $case_current_status: String
      $branch: String
    ) {
      setCaseProducts(
        case__c: $case__c
        external_id__c: $externalId
        sets: $sets
        parts: $parts
        is_hospital_owned__c: $is_hospital_owned
        division: $division
        is_submit: $is_submit
        case_current_status: $case_current_status
        branch: $branch
      ) {
        externalId
        sfId
        caseId
        status
        branch
        hospitalNoName
        surgeonName
        surgeonNoName
        storageExist
        procedures {
          sfid
          name
          cap_price__c
        }
        patientId
        urgentreplenish
        revision
        salesRep
        coveringRep
        capPrice
        surgeryDate
        deliveryDate
        deliveryOption
        shipTo
        shippingAddress
        billingToAddress
        shippingDate
        returnDate
        division
        internalComment
        hospitalName
        hospital_location__c
        hospAddress
        hospSiteNo
        hospAddrSiteNo
        hospAddrPrimary
        userRep
        coveringRepsSFIDs
        caseBranchId
        lastModifiedByUser
        lastModifiedDate
        mako
        adapt
        right
        left
        cancelReason
        cancelComment
        is_hospital_owned
        communityCase
        favoriteCase
        surgeonLastName
        hospitalid__c
        hospital_staffid__c
        signatureHospitalRepName
        signatureSalesRepName
        externalComments
        pouploaded
        address_name
        QuickQuoteRationale
        QuickQuoteCode
        address1__c
        address2__c
        city__c
        state_province__c
        postalcode__c
        ship_toaddress__r__external_id__c
        salesTeamsSFIDs
        covering_reps__c
        ship_toaddress__c
        usgreject_comments__c
        branchSfId
        webops_id__c
        image {
          CaseExternalId
          signature
          attachmentname
          attachmentid
          imageurl
          thumbnailurl
        }
        sets {
          set_id
          set_name
          requested_set_name
          kit_no__c
          label
          oracle_sub_inventory_location
          loaner__c
          status__c
          quantity__c
          productName
          productSfid
          type
          setType
          is_preference_product__c
          parts {
            part_ipc_sfid
            product_sfid
            part_id
            part_name
            part_desc
            lot_serial_control_code__c
            unit
            lot_number
            serial
            expiration_date
            is_lot_controlled
            productOracleId
          }
        }
        parts {
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          lot_serial_control_code__c
          unit
          lot_number
          serial
          expiration_date
          loaner__c
          status__c
          quantity__c
          is_preference_product__c
        }
        usages {
          sfid
          external_id__c
          case_sfid
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          quantity__c
          unit
          lot_number__c
          wasted
          side__c
          usg_price__c
          set_usages_id
          lot_serial_control_code__c
          lot_controlled__c
          ship_to__c
          replenish__c
          procedure__c
          surgical_cases__r__external_id__c
          surgical_cases__c
          procedureName
          price_override__c
          cap_price__c
          procedureid__c
          inventory_product_system__c
          used_from__c
          aro_ship_to__r__external_id__c
          productOracleId
          contract__c
          csr_contract__c
          csr_usage_price__c
          csr_price_override__c
          override_price__c
          usg_total_amount__c
          contractComments
          contractEndDate
          contractStartDate
          contractDescription
          contractName
        }
        additionalFees {
          case_sfid
          surgical_case__r__external_id__c
          surgical_case__c
          value__c
          name
          external_id__c
        }
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables,
  });
}

export async function setWebCaseProducts(variables) {
  const mutation = gql`
    mutation setWebCaseProducts(
      $case__c: String
      $externalId: String
      $is_hospital_owned: Boolean
      $sets: [CaseProductSetInput]
      $parts: [CaseProductPartInput]
      $division: String
      $is_submit: Boolean
      $case_current_status: String
      $branch: String
    ) {
      setWebCaseProducts(
        case__c: $case__c
        external_id__c: $externalId
        sets: $sets
        parts: $parts
        is_hospital_owned__c: $is_hospital_owned
        division: $division
        is_submit: $is_submit
        case_current_status: $case_current_status
        branch: $branch
      ) {
        externalId
        sfId
        caseId
        status
        branch
        hospitalNoName
        surgeonName
        surgeonNoName
        storageExist
        procedures {
          sfid
          name
          cap_price__c
        }
        patientId
        urgentreplenish
        revision
        salesRep
        coveringRep
        capPrice
        surgeryDate
        deliveryDate
        deliveryOption
        shipTo
        shippingAddress
        billingToAddress
        shippingDate
        returnDate
        division
        internalComment
        hospitalName
        hospital_location__c
        hospAddress
        hospSiteNo
        hospAddrSiteNo
        hospAddrPrimary
        userRep
        coveringRepsSFIDs
        caseBranchId
        lastModifiedByUser
        lastModifiedDate
        mako
        adapt
        right
        left
        cancelReason
        cancelComment
        is_hospital_owned
        communityCase
        favoriteCase
        surgeonLastName
        hospitalid__c
        hospital_staffid__c
        signatureHospitalRepName
        signatureSalesRepName
        externalComments
        pouploaded
        address_name
        QuickQuoteRationale
        QuickQuoteCode
        address1__c
        address2__c
        city__c
        state_province__c
        postalcode__c
        ship_toaddress__r__external_id__c
        salesTeamsSFIDs
        covering_reps__c
        ship_toaddress__c
        usgreject_comments__c
        branchSfId
        webops_id__c
        image {
          CaseExternalId
          signature
          attachmentname
          attachmentid
          imageurl
          thumbnailurl
        }
        sets {
          set_id
          set_name
          requested_set_name
          kit_no__c
          label
          oracle_sub_inventory_location
          loaner__c
          status__c
          quantity__c
          productName
          productSfid
          type
          setType
          is_preference_product__c
          parts {
            part_ipc_sfid
            product_sfid
            part_id
            part_name
            part_desc
            lot_serial_control_code__c
            unit
            lot_number
            serial
            expiration_date
            is_lot_controlled
            productOracleId
          }
        }
        parts {
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          lot_serial_control_code__c
          unit
          lot_number
          serial
          expiration_date
          loaner__c
          status__c
          quantity__c
          is_preference_product__c
        }
        usages {
          sfid
          external_id__c
          case_sfid
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          quantity__c
          unit
          lot_number__c
          wasted
          side__c
          usg_price__c
          set_usages_id
          lot_serial_control_code__c
          lot_controlled__c
          ship_to__c
          replenish__c
          procedure__c
          surgical_cases__r__external_id__c
          surgical_cases__c
          procedureName
          price_override__c
          cap_price__c
          procedureid__c
          inventory_product_system__c
          used_from__c
          aro_ship_to__r__external_id__c
          productOracleId
          contract__c
          csr_contract__c
          csr_usage_price__c
          csr_price_override__c
          override_price__c
          usg_total_amount__c
          contractComments
          contractEndDate
          contractStartDate
          contractDescription
          contractName
        }
        additionalFees {
          case_sfid
          surgical_case__r__external_id__c
          surgical_case__c
          value__c
          name
          external_id__c
        }
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables,
  });
}

export async function updateSetCatalogCategory(external_id, sets) {
  const mutation = gql`
    mutation updateSetCatalogCategory($external_id: String, $sets: [SetUpdate]) {
      updateSetCatalogCategory(external_id: $external_id, sets: $sets) {
        __typename
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: {
      external_id,
      sets
    },
  });
}

export async function createOrUpdateTPR(payload) {
  const mutation = gql`
    mutation createOrUpdateTPR($payload: TPRUpsertInput) {
      createOrUpdateTPR(payload: $payload) {
        tpr_name
        assigned_rep__c
        primary_mps__c
        hospital__c
        surgeon__c
        surgery_date__c
        branch__c
        status__c
        external_tpr_id__c
        sfid
        external_id__c
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: { payload },
  });
}

export async function trackPatientScan(caseSfid, fromCloud) {
  const mutation = gql`
    mutation trackPatientScan($caseSfid: String, $fromCloud: Boolean) {
      trackPatientScan(caseSfid: $caseSfid, fromCloud: $fromCloud)
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: { caseSfid, fromCloud },
  });
}

export async function trackTPRDownload(caseSfid, tprExternalId) {
  const mutation = gql`
    mutation trackTPRDownload($caseSfid: String, $tprExternalId: String) {
      trackTPRDownload(caseSfid: $caseSfid, tprExternalId: $tprExternalId)
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: { caseSfid, tprExternalId },
  });
}

export async function createOrUpdateCase(payload) {
  const mutation = gql`
    mutation createOrUpdateCase($id: String, $payload: CaseUpsertInput) {
      createOrUpdateCase(id: $id, payload: $payload) {
        externalId
        sfId
        caseId
        status
        branch
        hospitalNoName
        surgeonName
        surgeonNoName
        procedures {
          sfid
          name
          cap_price__c
        }
        storageExist
        patientId
        urgentreplenish
        revision
        salesRep
        coveringRep
        capPrice
        surgeryDate
        deliveryDate
        deliveryOption
        shipTo
        shippingAddress
        billingToAddress
        shippingDate
        returnDate
        division
        internalComment
        hospitalName
        userRep
        coveringRepsSFIDs
        caseBranchId
        createdDate
        lastModifiedByUser
        lastModifiedDate
        mako
        as1
        surgery_date_confirmed__c
        adapt
        right
        left
        cancelReason
        cancelComment
        is_hospital_owned
        communityCase
        favoriteCase
        surgeonLastName
        hospitalid__c
        hospital_staffid__c
        hospital_location__c
        hospAddress
        hospSiteNo
        hospAddrSiteNo
        hospAddrPrimary
        signatureHospitalRepName
        signatureSalesRepName
        externalComments
        pouploaded
        address_name
        address1__c
        address2__c
        city__c
        state_province__c
        postalcode__c
        ship_toaddress__r__external_id__c
        salesTeamsSFIDs
        covering_reps__c
        ship_toaddress__c
        usgreject_comments__c
        branchSfId
        webops_id__c
        patient_gender
        image {
          CaseExternalId
          signature
          attachmentname
          attachmentid
          imageurl
          thumbnailurl
        }
        sets {
          set_id
          set_name
          requested_set_name
          kit_no__c
          label
          oracle_sub_inventory_location
          loaner__c
          status__c
          quantity__c
          productName
          productSfid
          type
          is_preference_product__c
          setType
          parts {
            part_ipc_sfid
            product_sfid
            part_id
            part_name
            part_desc
            lot_serial_control_code__c
            unit
            lot_number
            serial
            expiration_date
            is_lot_controlled
            productOracleId
          }
        }
        parts {
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          lot_serial_control_code__c
          unit
          lot_number
          serial
          expiration_date
          loaner__c
          status__c
          quantity__c
          is_preference_product__c
        }
        usages {
          sfid
          external_id__c
          case_sfid
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          quantity__c
          unit
          lot_number__c
          wasted
          side__c
          usg_price__c
          set_usages_id
          lot_serial_control_code__c
          lot_controlled__c
          ship_to__c
          replenish__c
          procedure__c
          surgical_cases__r__external_id__c
          surgical_cases__c
          procedureName
          price_override__c
          cap_price__c
          procedureid__c
          inventory_product_system__c
          used_from__c
          aro_ship_to__r__external_id__c
          productOracleId
          contract__c
          csr_contract__c
          csr_usage_price__c
          csr_price_override__c
          override_price__c
          usg_total_amount__c
          contractComments
          contractEndDate
          contractStartDate
          contractDescription
          contractName
        }
        additionalFees {
          case_sfid
          surgical_case__r__external_id__c
          surgical_case__c
          value__c
          name
          external_id__c
        }
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: { id: "", payload },
  });
}

export async function cancelCase(external_id, sfid, reason, comments) {
  const mutation = gql`
    mutation cancelCase(
      $external_id: String
      $sfid: String
      $reason: String!
      $comments: String
    ) {
      cancelCase(
        external_id: $external_id
        sfid: $sfid
        reason: $reason
        comments: $comments
      )
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: {
      external_id,
      sfid,
      reason,
      comments,
    },
  });
}

export async function updateCaseByFields(variables) {
  const mutation = gql`
    mutation updateCaseByFields(
      $caseDetail: CaseDetailsInput
      $surgical_cases__r__external_id__c: String
      $surgical_cases__c: String
    ) {
      updateCaseByFields(
        caseDetail: $caseDetail
        surgical_cases__r__external_id__c: $surgical_cases__r__external_id__c
        surgical_cases__c: $surgical_cases__c
      ) {
        externalId
        sfId
        caseId
        status
        branch
        hospitalNoName
        surgeonName
        surgeonNoName
        storageExist
        procedures {
          sfid
          name
          cap_price__c
        }
        patientId
        urgentreplenish
        revision
        salesRep
        coveringRep
        capPrice
        surgeryDate
        deliveryDate
        deliveryOption
        shipTo
        shippingAddress
        billingToAddress
        createdDate
        shippingDate
        returnDate
        division
        internalComment
        hospital_location__c
        hospAddress
        hospSiteNo
        hospAddrSiteNo
        hospAddrPrimary
        hospitalName
        userRep
        coveringRepsSFIDs
        caseBranchId
        lastModifiedByUser
        lastModifiedDate
        mako
        adapt
        right
        left
        cancelReason
        cancelComment
        is_hospital_owned
        communityCase
        favoriteCase
        surgeonLastName
        hospitalid__c
        hospital_staffid__c
        QuickQuoteRationale
        QuickQuoteCode
        signatureHospitalRepName
        signatureSalesRepName
        externalComments
        pouploaded
        address_name
        address1__c
        address2__c
        city__c
        state_province__c
        postalcode__c
        ship_toaddress__r__external_id__c
        salesTeamsSFIDs
        signature_source__c
        covering_reps__c
        ship_toaddress__c
        usgreject_comments__c
        webops_id__c
        image {
          CaseExternalId
          signature
          attachmentname
          attachmentid
          imageurl
          thumbnailurl
        }
        externalComments
        sets {
          set_id
          set_name
          requested_set_name
          kit_no__c
          label
          oracle_sub_inventory_location
          loaner__c
          status__c
          quantity__c
          productName
          productSfid
          type
          is_preference_product__c
          setType
          parts {
            part_ipc_sfid
            product_sfid
            part_id
            part_name
            part_desc
            lot_serial_control_code__c
            unit
            lot_number
            serial
            expiration_date
            is_lot_controlled
            productOracleId
          }
        }
        parts {
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          lot_serial_control_code__c
          unit
          lot_number
          serial
          expiration_date
          loaner__c
          status__c
          quantity__c
          is_preference_product__c
        }
        usages {
          sfid
          external_id__c
          case_sfid
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          quantity__c
          unit
          lot_number__c
          serialNumber
          unitNumber
          expirationDate
          wasted
          side__c
          usg_price__c
          set_usages_id
          lot_serial_control_code__c
          lot_controlled__c
          ship_to__c
          replenish__c
          procedure__c
          surgical_cases__r__external_id__c
          surgical_cases__c
          procedureName
          price_override__c
          cap_price__c
          procedureid__c
          inventory_product_system__c
          used_from__c
          aro_ship_to__r__external_id__c
          productOracleId
          contract__c
          csr_contract__c
          csr_usage_price__c
          csr_price_override__c
          override_price__c
          usg_total_amount__c
          contractComments
          contractEndDate
          contractStartDate
          contractDescription
          contractName
        }
        additionalFees {
          case_sfid
          surgical_case__r__external_id__c
          surgical_case__c
          value__c
          name
          external_id__c
        }
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: {
      surgical_cases__r__external_id__c: variables.externalId,
      surgical_cases__c: variables.sfId,
      caseDetail: variables.caseDetail,
    },
  });
}

export async function updateSurgerySheet(
  case_sfid,
  case_external_id,
  internal_comments,
  external_comments,
  hospital_printed_name,
  sales_rep,
  status
) {
  const mutation = gql`
    mutation updateSurgerySheet(
      $case_sfid: String
      $case_external_id: String
      $internal_comments: String
      $external_comments: String
      $hospital_printed_name: String
      $sales_rep: String
      $status: String
    ) {
      updateSurgerySheet(
        case_sfid: $case_sfid
        case_external_id: $case_external_id
        internal_comments: $internal_comments
        external_comments: $external_comments
        hospital_printed_name: $hospital_printed_name
        sales_rep: $sales_rep
        status: $status
      ) {
        sfId
        externalId
        internalComment
        externalComments
        signatureHospitalRepName
        signatureSalesRepName
        status
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: {
      case_sfid,
      case_external_id,
      internal_comments,
      external_comments,
      hospital_printed_name,
      sales_rep,
      status,
    },
  });
}

export async function deleteImages(
  surgical_cases__r__external_id__c,
  surgical_cases__c,
  images
) {
  const mutation = gql`
    mutation deleteImages(
      $surgical_cases__r__external_id__c: String
      $surgical_cases__c: String
      $images: [CaseUsageImages]
    ) {
      deleteImages(
        surgical_cases__r__external_id__c: $surgical_cases__r__external_id__c
        surgical_cases__c: $surgical_cases__c
        images: $images
      ) {
        image {
          CaseExternalId
          signature
          imageurl
          thumbnailurl
          attachmentname
          attachmentid
        }
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: {
      surgical_cases__r__external_id__c,
      surgical_cases__c,
      images,
    },
  });
}

export async function updatePartsUsed(
  case_sfid,
  case_external_id,
  parts,
  hospital_printed_name,
  sales_rep,
  status,
  print_data__c
) {
  const mutation = gql`
    mutation updatePartsUsed(
      $case_sfid: String
      $case_external_id: String
      $parts: [CaseUsageUpsertInput]
      $hospital_printed_name: String
      $sales_rep: String
      $status: String
      $print_data__c: String
    ) {
      updatePartsUsed(
        case_sfid: $case_sfid
        case_external_id: $case_external_id
        parts: $parts
        hospital_printed_name: $hospital_printed_name
        sales_rep: $sales_rep
        status: $status
        print_data__c: $print_data__c
      ) {
        sfId
        externalId
        signatureHospitalRepName
        signatureSalesRepName
        status
       
        usages {
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          lot_serial_control_code__c
          quantity__c
          lot_number__c
          wasted
          side__c
          set_usages_id
          lot_controlled__c
          ship_to__c
          shipto_location__c
          replenish__c
          procedure__c
          sfid
          external_id__c
          procedureid__c
          inventory_product_system__c
          productOracleId
          serialNumber
          dns__c
          inventory__c
          aro_reference_number__c
          ship_priority__c
          ship_method__c
          revisions__c
          
        }
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: {
      case_sfid,
      case_external_id,
      parts,
      hospital_printed_name,
      sales_rep,
      status,
      print_data__c
    },
  });
}


//QQCode
export async function setContractPrices(variables) {
  const mutation = gql`
    mutation setContractPrices(
      $sfid: String,
      $external_id: String,
      $quickquote_code: String,
      $quickquote_rationale: String,
      $usage_override: Boolean,
      $usage_finalamount: String,
      $freight_charges: String,
      $cap_construct_name: String,
      $cap_contract_id: String,
      $upcharge_total: String,
      $total_cap_price__c: String,
      $cap_contractId: String,
      $cap_contract_name: String,
      $usagePriceContracts: [UsagePriceContract]
    ) {
      setContractPrices(
        sfid: $sfid,
        external_id: $external_id,
        quickquote_code: $quickquote_code,
        quickquote_rationale: $quickquote_rationale,
        usage_override: $usage_override,
        usage_finalamount: $usage_finalamount,
        freight_charges: $freight_charges,
        cap_construct_name: $cap_construct_name,
        cap_contract_id: $cap_contract_id,
        upcharge_total:$upcharge_total,
        total_cap_price__c: $total_cap_price__c,
        cap_contractId: $cap_contractId,
        cap_contract_name: $cap_contract_name,
        usagePriceContracts: $usagePriceContracts
      ) {
        sfId
        externalId
        caseId
        capPrice
        total_cap_price__c
        usage_finalamount__c
        quickquote_code__c
        quickquote_rationale__c
        usages {
          sfid
          external_id__c
          part_name
          part_desc
          contract__c
          quantity__c
          usg_price__c
          usg_total_amount__c
          override_price__c
          wasted
        }
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables
  });
}

export function transfromCapPricesToPayload(
  caseDetailsObj,
  selectedParts,
  capPriceTotal,
  upchargeTotal,
  capPrice,
  freightCharge
) {
  
  if (isNaN(upchargeTotal)) {
    upchargeTotal = 0;
  }
  if (isNaN(capPriceTotal)) {
    capPriceTotal = 0;
  }
  if (isNaN(freightCharge)) {
    freightCharge = 0;
  }
  const { externalId, sfId } = caseDetailsObj;

  let transformedPart = {
    sfid: sfId,
    external_id: externalId,
    cap_price: capPrice,
    total_cap_price: capPriceTotal,
    usage_override: null, //false,
    usage_finalamount: upchargeTotal,
    freight_charges: freightCharge
  };

  let usagePrices = selectedParts.map((item, index) => {
    let quantity = item.quantity__c;
    if (isNaN(quantity)) Number(quantity);
    let price =
      item.usg_price__c && item.usg_price__c
        ? parseFloat(item.usg_price__c)
        : 0;
    if (isNaN(price)) price = 0;

    return {
      sfid: item.sfid,
      external_id: item.external_id__c,
      usg_price: price,
      price_override: item.override_price__c, //price !== 0,
      usg_total_amount: quantity * price,
      quantity: quantity
    };
  });
  transformedPart['usagePrices'] = usagePrices;
  return transformedPart;
}

export async function setImages(surgical_cases__r__external_id__c, surgical_cases__c, images, po_uploaded__c) {	
  const mutation = gql`	
    mutation addImages(	
      $surgical_cases__r__external_id__c: String	
      $surgical_cases__c: String	
      $images: [CaseUsageImages]	
      $po_uploaded__c: Boolean	
    ) {	
      addImages(	
        surgical_cases__r__external_id__c: $surgical_cases__r__external_id__c	
        surgical_cases__c: $surgical_cases__c	
        images: $images	
        po_uploaded__c: $po_uploaded__c	
      ) {	
        image {	
          CaseExternalId	
          signature	
          imageurl	
          thumbnailurl	
          attachmentname	
          attachmentid	
        }	
      }	
    }	
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: {
      surgical_cases__r__external_id__c,
      surgical_cases__c,
      images,
      po_uploaded__c,
    },
  });
}

export async function updateCapPrice(variables) {
  const mutation = gql`
    mutation setCapPrices(
      $sfid: String
      $external_id: String
      $cap_price: Boolean
      $total_cap_price: String
      $usage_override: Boolean
      $usage_finalamount: String
      $freight_charges: String
      $usagePrices: [UsagePrice]
    ) {
      setCapPrices(
        sfid: $sfid
        external_id: $external_id
        cap_price: $cap_price
        total_cap_price: $total_cap_price
        usage_override: $usage_override
        usage_finalamount: $usage_finalamount
        freight_charges: $freight_charges
        usagePrices: $usagePrices
      ) {
        sfId
        externalId
        caseId
        capPrice
        total_cap_price__c
        usage_finalamount__c
        usages {
          sfid
          external_id__c
          part_name
          part_desc
          quantity__c
          usg_price__c
          usg_total_amount__c
          price_override__c
        }
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables,
  });
}

export async function setCasesUsage(variables) {
  const mutation = gql`
    mutation setCasesUsage(
      $caseDetail: CaseDetailsInput
      $surgical_cases__r__external_id__c: String
      $surgical_cases__c: String
      $parts: [CaseUsageUpsertInput]
      $surgeryCaseProcedures: [CaseProcedureInput]
      $additionalFees: [CaseUsageAdditionalFeeInput]
      $images: [CaseUsageImages]
      $mode: Int
    ) {
      setCasesUsage(
        caseDetail: $caseDetail
        surgical_cases__r__external_id__c: $surgical_cases__r__external_id__c
        surgical_cases__c: $surgical_cases__c
        parts: $parts
        surgeryCaseProcedures: $surgeryCaseProcedures
        additionalFees: $additionalFees
        images: $images
        mode: $mode
      ) {
        externalId
        sfId
        caseId
        status
        branch
        hospitalNoName
        surgeonName
        surgeonNoName
        storageExist
        procedures {
          sfid
          name
          cap_price__c
        }
        patientId
        urgentreplenish
        revision
        salesRep
        coveringRep
        capPrice
        surgeryDate
        deliveryDate
        deliveryOption
        shipTo
        shippingAddress
        billingToAddress
        shippingDate
        returnDate
        division
        internalComment
        hospitalName
        userRep
        coveringRepsSFIDs
        caseBranchId
        lastModifiedByUser
        lastModifiedDate
        mako
        adapt
        right
        left
        cancelReason
        cancelComment
        is_hospital_owned
        communityCase
        favoriteCase
        surgeonLastName
        hospitalid__c
        hospital_staffid__c
        hospital_location__c
        hospAddress
        hospSiteNo
        hospAddrSiteNo
        hospAddrPrimary
        QuickQuoteRationale
        QuickQuoteCode
        signatureHospitalRepName
        signatureSalesRepName
        externalComments
        pouploaded
        address_name
        address1__c
        address2__c
        city__c
        state_province__c
        postalcode__c
        ship_toaddress__r__external_id__c
        salesTeamsSFIDs
        signature_source__c
        covering_reps__c
        ship_toaddress__c
        usgreject_comments__c
        webops_id__c
        image {
          CaseExternalId
          signature
          attachmentname
          attachmentid
          imageurl
          thumbnailurl
        }
        externalComments
        sets {
          set_id
          set_name
          requested_set_name
          kit_no__c
          label
          oracle_sub_inventory_location
          loaner__c
          status__c
          quantity__c
          productName
          productSfid
          type
          is_preference_product__c
          setType
          parts {
            part_ipc_sfid
            product_sfid
            part_id
            part_name
            part_desc
            lot_serial_control_code__c
            unit
            lot_number
            serial
            expiration_date
            is_lot_controlled
            productOracleId
          }
        }
        parts {
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          lot_serial_control_code__c
          unit
          lot_number
          serial
          expiration_date
          loaner__c
          status__c
          quantity__c
          is_preference_product__c
        }
        usages {
          sfid
          external_id__c
          case_sfid
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          quantity__c
          unit
          lot_number__c
          serialNumber
          unitNumber
          expirationDate
          wasted
          side__c
          usg_price__c
          set_usages_id
          lot_serial_control_code__c
          lot_controlled__c
          ship_to__c
          replenish__c
          procedure__c
          surgical_cases__r__external_id__c
          surgical_cases__c
          procedureName
          price_override__c
          cap_price__c
          procedureid__c
          inventory_product_system__c
          used_from__c
          aro_ship_to__r__external_id__c
          productOracleId
          contract__c
          csr_contract__c
          csr_usage_price__c
          csr_price_override__c
          override_price__c
          usg_total_amount__c
          contractComments
          contractEndDate
          contractStartDate
          contractDescription
          contractName
        }
        additionalFees {
          case_sfid
          surgical_case__r__external_id__c
          surgical_case__c
          value__c
          name
          external_id__c
        }
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables,
  });
}

export async function appSettingNotificationUpdate(variables) {
  const mutation = gql`
    mutation updateAppSetting(
      $oracle_order_notify__c: Boolean!
      $ship_ready_for_surgery_sets_notification__c: Boolean!
      $sets_cancelled_removed_notification__c: Boolean!
      $case_cancelled_notification__c: Boolean!
      $requested_sets_notification__c: Boolean!
      $send_email_invite_notification__c: Boolean!
      $external_id__c: String
      $sfid: String
      $branch__c: String!
    ) {
      appSettingsUpdate(
        oracle_order_notify__c: $oracle_order_notify__c
        ship_ready_for_surgery_sets_notification__c: $ship_ready_for_surgery_sets_notification__c
        sets_cancelled_removed_notification__c: $sets_cancelled_removed_notification__c
        case_cancelled_notification__c: $case_cancelled_notification__c
        requested_sets_notification__c: $requested_sets_notification__c
        send_email_invite_notification__c: $send_email_invite_notification__c
        external_id__c: $external_id__c
        sfid: $sfid
        branch__c: $branch__c
      )
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables,
  });
}

export async function appSettingNotification(variables) {
  const mutation = gql`
    mutation appSettingsUpdate(
      $requested_sets_notification__c: Boolean!
      $oracle_order_notify__c: Boolean!
      $ship_ready_for_surgery_sets_notification__c: Boolean!
      $sets_cancelled_removed_notification__c: Boolean!
      $send_email_invite_notification__c: Boolean!
      $case_cancelled_notification__c: Boolean!
      $new_patient_scan_available__c: Boolean
      $tpr_cancelled__c: Boolean
      $tpr_creation_notification__c: Boolean
      $tpr_indesign_notification__c: Boolean
      $tpr_rejection_notification__c: Boolean
      $tpr_submission_notification__c: Boolean
      $tpr_treatmentplanavailable_notification__c: Boolean
      $treatment_plan_downloaded__c: Boolean
      $external_id__c: String
      $sfid: String
      $branch__c: String!
      $tpr_created_awaiting_ct_scan__c: Boolean
      $tpr_surgical_plan_available_notiify__c: Boolean
      $tpr_completed_notification__c: Boolean
      $tpr_surgicalplan_conditionally_approved__c: Boolean
      $tpr_surgicalplan_change_requested__c: Boolean
      $tpr_surgical_plan_approved__c: Boolean
      $tpr_finalplanavail_manf__c: Boolean
      $tpr_shiped_to_sales_notification__c: Boolean
      $tpr_as1_submmision_notification__c: Boolean
      $tpr_as1_indesign_notification__c: Boolean
      $tpr_as1_cancelled_notification__c: Boolean
      $tpr_as1_rejected_notification__c: Boolean
      $tpr_as1_signed_off_notification__c: Boolean
    ) {
      appSettingsUpdate(
        requested_sets_notification__c: $requested_sets_notification__c
        oracle_order_notify__c: $oracle_order_notify__c
        ship_ready_for_surgery_sets_notification__c: $ship_ready_for_surgery_sets_notification__c
        sets_cancelled_removed_notification__c: $sets_cancelled_removed_notification__c
        send_email_invite_notification__c: $send_email_invite_notification__c
        case_cancelled_notification__c: $case_cancelled_notification__c
        new_patient_scan_available__c: $new_patient_scan_available__c
        tpr_cancelled__c: $tpr_cancelled__c
        tpr_creation_notification__c: $tpr_creation_notification__c
        tpr_indesign_notification__c: $tpr_indesign_notification__c
        tpr_rejection_notification__c: $tpr_rejection_notification__c
        tpr_submission_notification__c: $tpr_submission_notification__c
        tpr_treatmentplanavailable_notification__c: $tpr_treatmentplanavailable_notification__c
        treatment_plan_downloaded__c: $treatment_plan_downloaded__c
        external_id__c: $external_id__c
        sfid: $sfid
        branch__c: $branch__c
        tpr_created_awaiting_ct_scan__c: $tpr_created_awaiting_ct_scan__c
        tpr_surgical_plan_available_notiify__c: $tpr_surgical_plan_available_notiify__c
        tpr_completed_notification__c: $tpr_completed_notification__c
        tpr_surgicalplan_conditionally_approved__c: $tpr_surgicalplan_conditionally_approved__c
        tpr_surgicalplan_change_requested__c: $tpr_surgicalplan_change_requested__c
        tpr_surgical_plan_approved__c: $tpr_surgical_plan_approved__c
        tpr_finalplanavail_manf__c: $tpr_finalplanavail_manf__c
        tpr_shiped_to_sales_notification__c: $tpr_shiped_to_sales_notification__c
        tpr_as1_submmision_notification__c: $tpr_as1_submmision_notification__c
        tpr_as1_indesign_notification__c: $tpr_as1_indesign_notification__c
        tpr_as1_cancelled_notification__c: $tpr_as1_cancelled_notification__c
        tpr_as1_rejected_notification__c: $tpr_as1_rejected_notification__c
        tpr_as1_signed_off_notification__c: $tpr_as1_signed_off_notification__c
      )
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables,
  });
}

export function createOrUpdateTPRFilter(payload) {
  const mutation = gql`
    mutation createOrUpdateTPRFilter(
      $procedureType: String
      $status: String
      $createdIn: String
      $branch: String
      $procedure: String
      $myTpr: Boolean
      $name: String
    ) {
      createOrUpdateTPRFilter(
        procedureType: $procedureType
        status: $status
        createdIn: $createdIn
        branch: $branch
        procedure: $procedure
        myTpr: $myTpr
        name: $name
      ) {
        name
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: payload,
  });
}

export const transfromNotificationsToPayload = (
  checkList,
  external_id__c,
  sfid
) => {
  let payload = {};
  Object.keys(checkList).map((element) => {
    payload[element] = checkList[element].status || false;
  });
  if (external_id__c) payload.external_id__c = external_id__c;
  if (sfid) payload.sfid = sfid;
  return payload;
};

export function transformCaseUsagesToPayload(
  caseObj,
  selectedParts,
  otherFees,
  caseObjectPaylod,
  secureUrlOfImages,
  secureUrlOfSign,
  signatureParentTab
) {
  const { externalId, sfId, procedures } = caseObj;
  const parts = selectedParts.map((part) => {
    let {
      partInfo: {
        product_sfid,
        lot_number,
        is_lot_controlled,
        price,
        unitNumber,
        serialNumber,
        expirationDate,
        price_override__c,
        override_price__c,
        usg_price__c,
        contract__c,
        csr_contract__c,
        csr_usage_price__c,
        csr_price_override__c,
        usg_total_amount__c,
      },
      addPartsQuantity,
      procedureUsage,
      replenish,
      replenishTo,
      setUsages,
      wasted,
      address,
      aro_ship_to__r__external_id__c,
      isSide,
      sfid,
      external_id__c,
    } = part;

    let shipTo = null;

    if (replenishTo) {
      shipTo =
        replenishTo.label === "Other" && address
          ? address.address
          : replenishTo.label !== "Other"
          ? replenishTo.label
          : "";
    }

    let transformed = {
      sfid,
      external_id__c,
      surgical_cases__r__external_id__c: externalId,
      surgical_cases__c: sfId,
      product__c: product_sfid,
      quantity__c: addPartsQuantity ? `${addPartsQuantity.value}` : null,
      lot_number__c: lot_number || "",
      unit_number__c: unitNumber || "",
      side__c: isSide ? isSide.label : "",
      // used_from__c: setUsages ? setUsages.value : '',
      lot_controlled__c: is_lot_controlled || false,
      ship_to__c: shipTo || "",
      replenish__c: replenish || false,
      procedure__c: procedureUsage ? procedureUsage.value : "",
      expiry_date__c: expirationDate || null,
      serial_number__c: serialNumber || "",
      wasteds__c: wasted,
      contract__c: contract__c || null,
      csr_contract__c: csr_contract__c || null,
      usg_price__c: usg_price__c ? `${usg_price__c}` : null,
      csr_usage_price__c: csr_usage_price__c || null,
      price_override__c: override_price__c ? `${price_override__c}` : null,
      csr_price_override__c: csr_price_override__c || null,
      override_price__c: override_price__c || false,
      usg_total_amount__c: usg_total_amount__c
        ? `${usg_total_amount__c}`
        : "0.0",
    };

    if (setUsages && setUsages.value) {
      switch (typeof setUsages.value) {
        case "string":
          transformed.used_from__c = setUsages.value;
          break;
        case "object":
          transformed.inventory_product_system__c = setUsages.value.set_id;
          break;
      }
    }

    if (aro_ship_to__r__external_id__c) {
      transformed.aro_ship_to__r__external_id__c =
        aro_ship_to__r__external_id__c;
    }

    return transformed;
  });

  const surgeryCaseProcedures = procedures
    ? procedures.map((item) => {
        const availableProcedure = selectedParts.filter(
          (part) =>
            part.procedureUsage &&
            part.procedureUsage.value === item.sfid &&
            part.procedureUsage.price
        );
        const procedureId = item.sfid;
        const procedurePrice =
          availableProcedure.length > 0
            ? `${availableProcedure[0].procedureUsage.price}`
            : 0;
        return {
          casesid__r__external_id__c: externalId,
          casesid__c: sfId,
          procedureid__c: procedureId,
          cap_price__c: procedurePrice,
        };
      })
    : [];

  const additionalFees = otherFees.map((fees) => {
    const { external_id__c, fee, value } = fees;

    return {
      surgical_case__r__external_id__c: externalId,
      surgical_case__c: sfId,
      value__c: value || "0.0",
      name: fee || "",
    };
  });

  let case_images = [];

  if (!isEmpty(secureUrlOfImages)) {
    case_images = secureUrlOfImages.map((image) => {
      const result = image.url ? image.url.split("/") : [];
      const thumbnailUrlArr = image.thumbnailUrl
        ? image.thumbnailUrl.split("/")
        : [];

      return {
        case_external_id: externalId,
        case_sfid: sfId,
        azure_image_url: result[result.length - 1],
        thumbnail_url: thumbnailUrlArr[thumbnailUrlArr.length - 1],
        attachment_name: image.attachmentname,
        attachment_id: image.attachmentid,
        isNew: image.isNew,
      };
    });
  }

  if (!isEmpty(secureUrlOfSign)) {
    const hospitalResult = secureUrlOfSign.hospital
      ? secureUrlOfSign.hospital.url.split("/")
      : [];
    const hospitalSignatureNew = secureUrlOfSign.hospital
      ? secureUrlOfSign.hospital.isNew
      : false;

    const repResult = secureUrlOfSign.rep
      ? secureUrlOfSign.rep.url.split("/")
      : [];

    const repSignatureNew = secureUrlOfSign.rep
      ? secureUrlOfSign.rep.isNew
      : false;

    case_images = [
      ...case_images,
      {
        case_external_id: externalId,
        case_sfid: sfId,
        azure_image_url: hospitalResult[hospitalResult.length - 1],
        signature_by: "hosPital_Sig",
        isNew: hospitalSignatureNew,
      },
      {
        case_external_id: externalId,
        case_sfid: sfId,
        azure_image_url: repResult[repResult.length - 1],
        signature_by: "rep_Sig",
        isNew: repSignatureNew,
      },
    ];
  }

  if (!isEmpty(secureUrlOfSign))
    caseObjectPaylod.signature_source__c = signatureParentTab || null;

  const payload = {
    surgical_cases__r__external_id__c: externalId,
    surgical_cases__c: sfId || "",
    caseDetail: caseObjectPaylod,
    parts,
    surgeryCaseProcedures,
    additionalFees,
    images: case_images,
  };
  return payload;
}

export function transformCaseUsagesToForm(caseDetails) {
  let { usages, additionalFees, capPrice, procedures } = caseDetails;
  const replenishToArr = [
    {
      value: 1,
      label: "Home",
    },
    {
      value: 2,
      label: "Branch",
    },
    {
      value: 3,
      label: "Hospital",
    },
    {
      value: 4,
      label: "Other",
    },
  ];
  const selectedParts = usages.map((part) => {
    const {
      product_sfid,
      part_name,
      part_ipc_sfid,
      lot_serial_control_code__c,
      lot_controlled__c,
      unit,
      quantity__c,
      lot_number__c,
      wasted,
      side__c,
      usg_price__c,
      set_usages_id,
      ship_to__c,
      replenish__c,
      procedure__c,
      procedureName,
      price_override__c,
      external_id__c,
      sfid,
      inventory_product_system__c,
      used_from__c,
      aro_ship_to__r__external_id__c,
      usg_total_amount__c,
      override_price__c,
      contract__c,
      csr_contract__c,
      csr_usage_price__c,
      csr_price_override__c,
      replenishAddressName,
      replenishAddressAddressField,
      replenishAddressCityField,
      replenishAddressZipField,
      replenishAddressStateField,
      replenishAddressExternalIdField,
      serialNumber,
      unitNumber,
      expirationDate,
      contractComments,
      contractEndDate,
      contractStartDate,
      contractDescription,
      contractName,
    } = part;

    const usagesProcedures = procedures.filter(
      (procedure) => procedure.sfid === procedure__c
    );

    let cap_price__c = 0;

    if (usagesProcedures && usagesProcedures.length > 0)
      cap_price__c = usagesProcedures[0].cap_price__c;

    let replenishTo = {};

    if (JSON.parse(replenish__c) && ship_to__c !== undefined) {
      switch (ship_to__c) {
        case "Home":
          replenishTo = {
            value: 1,
            label: "Home",
          };
          break;
        case "Branch":
          replenishTo = {
            value: 2,
            label: "Branch",
          };
          break;
        case "Hospital":
          replenishTo = {
            value: 3,
            label: "Hospital",
          };
          break;
        default:
          replenishTo = {
            value: 4,
            label: "Other",
          };
          break;
      }
    }

    let expireFormattedDate = "";

    if (expirationDate && expirationDate.length > 0) {
      expireFormattedDate = moment(expirationDate).format();
    }

    let transformed = {
      partInfo: {
        product_sfid,
        part_psfid: part_ipc_sfid,
        part_name,
        unit,
        unitNumber: unitNumber || "",
        lot_serial_control_code__c,
        serialNumber: serialNumber || "",
        expirationDate: expireFormattedDate,
        is_lot_controlled: getLotControlled(lot_controlled__c),
        lot_number: lot_number__c,
        contract__c,
        csr_contract__c,
        usg_price__c,
        csr_usage_price__c,
        override_price__c,
        price_override__c,
        csr_price_override__c,
        price: capPrice
          ? csr_price_override__c
          : usg_price__c || price_override__c, // pref goes to contract price
        usg_total_amount__c,
      },
      contractInformation: contract__c && {
        contractSfid: contract__c,
        contractComments,
        contractEndDate,
        contractStartDate,
        contractDescription,
        contractName,
      },
      procedureUsage: {
        label: procedureName,
        value: procedure__c,
        price: cap_price__c || 0,
      },
      addPartsQuantity: {
        label: quantity__c,
        value: quantity__c,
      },
      partName: part_name,
      replenishTo: replenishTo || {},
      setUsages: {
        label: set_usages_id || "",
        value: set_usages_id || "",
      },
      replenish: JSON.parse(replenish__c),
      wasted: JSON.parse(wasted),
      isMemberOpen: false,
      external_id__c,
      sfid,
      isSide: {
        label: side__c || "",
        value: side__c || "",
      },
    };

    if (used_from__c || inventory_product_system__c) {
      transformed.setUsages = {
        label: used_from__c,
        value: inventory_product_system__c || used_from__c,
      };

      if (inventory_product_system__c) {
        let matchingSet = caseDetails.sets.find(
          (set) => set.set_id === inventory_product_system__c
        );
        if (matchingSet) {
          transformed.setUsages.value = matchingSet;
          transformed.setUsages.label = matchingSet.kit_no__c
            ? matchingSet.kit_no__c.concat(" - ").concat(matchingSet.set_name)
            : matchingSet.set_name;
        }
      }
    }

    if (aro_ship_to__r__external_id__c) {
      transformed.aro_ship_to__r__external_id__c =
        aro_ship_to__r__external_id__c;
    }

    if (
      replenish__c &&
      ship_to__c !== undefined &&
      ship_to__c !== "Home" &&
      ship_to__c !== "Branch" &&
      ship_to__c !== "Hospital"
    ) {
      transformed.address = {
        address: ship_to__c || "",
      };
    }
    return transformed;
  });

  const otherFees = [...additionalFees].map((item) => {
    const {
      surgical_case__r__external_id__c,
      surgical_case__c,
      value__c,
      name,
      external_id__c,
    } = item;

    return {
      caseExternalId: surgical_case__r__external_id__c,
      caseSfid: surgical_case__c,
      fee: name,
      value: value__c,
      external_id__c,
    };
  });

  return { selectedParts, otherFees };
}

const getLotControlled = (lot_controlled__c) => {
  let lotControlled = false;

  switch (typeof lot_controlled__c) {
    case "string":
      lotControlled = lot_controlled__c == "true";
      break;
    case "boolean":
      lotControlled = lot_controlled__c;
      break;
  }

  return lotControlled;
};

export function transformCaseUsagesToMyCasesRecord(
  caseObj,
  selectedParts,
  otherFees
  // publicIdsOfSign,
  // publicIdsOfImages
) {
  const { externalId, sfId } = caseObj;

  let usages = selectedParts.map((part) => {
    let shipTo = "";

    if (part.replenishTo) {
      shipTo =
        part.replenishTo.label === "Other" && part.address
          ? part.address.address
          : part.replenishTo.label !== "Other"
          ? part.replenishTo.label
          : "";
    }
    let transformedPart = {
      surgical_cases__c: sfId,
      surgical_cases__r__external_id__c: externalId,
      product_sfid: part.partInfo.product_sfid || null,
      part_ipc_sfid: null,
      part_name: part.partInfo.part_name || "",
      part_desc: null,
      quantity__c: part.addPartsQuantity.value || "0",
      unit: part.partInfo.unit || "0",
      lot_number__c: part.partInfo.lot_number || "",
      side__c: part.side || "", // TODO: Is this valid?
      set_usages_id: part.setUsages.value || "",
      lot_serial_control_code__c:
        part.partInfo.lot_serial_control_code__c || "",
      lot_controlled__c: part.partInfo.is_lot_controlled || "",
      ship_to__c: shipTo || "",
      replenish__c: part.replenish || false,
      procedure__c: part.procedureUsage ? part.procedureUsage.value : null,
      procedureName: part.procedureUsage ? part.procedureUsage.label : null,
      cap_price__c: 0, // TODO: Where does this come from?
      procedureid__c: part.procedureUsage ? part.procedureUsage.value : null,
      contract__c: part.partInfo.contract__c || null,
      csr_contract__c: part.partInfo.csr_contract__c || null,
      usg_price__c: part.partInfo.usg_price__c || null,
      csr_usage_price__c: part.partInfo.csr_usage_price__c || null,
      price_override__c: part.partInfo.price_override__c || null,
      csr_price_override__c: part.partInfo.csr_price_override__c || null,
      override_price__c: part.partInfo.override_price__c || false,
      usg_total_amount__c: part.partInfo.usg_total_amount__c || 0.0,
      wasted: part.wasted || false,
    };

    return transformedPart;
  });

  let additionalFees = otherFees.map((otherFee) => ({
    case_sfid: sfId,
    surgical_cases__r__external_id__c: externalId,
    surgical_case__c: sfId,
    value__c: otherFee.value,
    name: otherFee.fee,
  }));

  return { usages, additionalFees };
}

export function transformCaseObjToCloneCaseGQLPayload(
  caseDetail,
  isIncludeComments
) {
  const {
    division,
    caseBranchId,
    capPrice,
    revision,
    favoriteCase,
    userRep,
    coveringRepsSFIDs,
    coveringRep,
    deliveryOption,
    shipTo,
    shippingAddress,
    billingToAddress,
    internalComment,
    mako,
    adapt,
    left,
    right,
    is_hospital_owned,
    hospitalid__c,
    hospital_staffid__c,
    procedures,
    address_name,
    address1__c,
    address2__c,
    city__c,
    state_province__c,
    postalcode__c,
  } = caseDetail;

  let payload = {
    division__c: division || "",
    branchid__c: caseBranchId || "",
    patient_info__c: "",
    cap_price__c: capPrice || "0",
    revisions__c: revision || false,
    favourite__c: favoriteCase || false,
    procedureid__c:
      procedures && procedures.length > 0
        ? procedures.map((procedure) => procedure.sfid).join(",")
        : "",
    kit_assigner__c: userRep || "",
    covering_reps__c: coveringRepsSFIDs || "",
    coveringRepsName: coveringRep || "",
    delivery_options__c: deliveryOption || "",
    ship_to__c: shipTo || "",
    left__c: left || false,
    right__c: right || false,
    mako__c: mako || false,
    adapt__c: adapt || false,
    status__c: "New",
    is_hospital_owned__c: is_hospital_owned || false,
    hospitalid__c,
    hospital_staffid__c,
    procedure_list__c:
      procedures && procedures.length > 0
        ? procedures.map((procedure) => procedure.name).join(",")
        : "",
  };

  if (address_name && address_name.length > 0) {
    payload.ship_to_address = {
      name: address_name || "",
      address: address1__c || "",
      address2: address2__c || "",
      city: city__c || "",
      state: state_province__c || "",
      zip: postalcode__c || "",
    };
  }

  if (isIncludeComments) payload.case_coverage__c = internalComment || "";

  return payload;
}

export function transformObjToUpsertPreferenceGQLPayload(name, comment, form) {
  const surgeonName =
    form &&
    form.surgeon &&
    form.surgeon.label &&
    form.surgeon.label.split("-").slice(1).join("-").trim();
  let preferenceName = "";
  if (surgeonName && form.procedures && form.procedures.length > 0) {
    preferenceName = `${surgeonName}-${form.procedures[0].label}`;
  }
  const payload = {
    surgeon_perference: {
      name: name || preferenceName,
      hospitalid__c:
        form && form.hospital && form.hospital.value ? form.hospital.value : "",
      branchid__c:
        form && form.branch && form.branch.value ? form.branch.value : "",
      contactid__c:
        form && form.surgeon && form.surgeon.value ? form.surgeon.value : "",
      procedureid__c:
        form && form.procedures && form.procedures.length > 0
          ? form.procedures[0].value
          : "",
      surg_pref_comments__c: comment,
      nickname : form && form.nick_name__c ? form.nick_name__c : ''
    },
  };
  let parts = form.selectedParts.map((part) => {
    return {
      ipsid__c: null,
      product_system__c: null,
      productid__c: part.product_sfid,
      isloaner__c: part.is_loaner,
    };
  });
  const sets = form.selectedSets.map(({ id, type }) => ({
    ipsid__c: type === "consignment" ? id : null,
    product_system__c: type === "loaner" ? id : null,
    productid__c: null,
    isloaner__c: type === "consignment" ? false : true,
  }));

  payload.preference_details = [...parts, ...sets];
  return payload;
}

export async function updateSets({ sets }) {
  const payload = sets.map(({ inventory_type, Status__c, set_id }) => ({
    inventory_type: inventory_type,
    status__c: Status__c,
    set_id: set_id,
  }));
  const result = await updateSet({ payload });
  return result;
}

export async function cloneCaseObj(
  caseDetailsObj,
  { isIncludeSets, isIncludeUsages, isIncludeComments }
) {
  let createdCase;
  let capturedProcedures; // HACK TO ENSURE PROCEDURES ARE RETAINED
  try {
    let { sets, parts, usages, additionalFees, division, status } =
      caseDetailsObj;
    const casePaylod = transformCaseObjToCloneCaseGQLPayload(
      caseDetailsObj,
      isIncludeComments
    );
    const results = await createOrUpdateCase(casePaylod);
    if (results && results.data && results.data.createOrUpdateCase) {
      createdCase = results.data.createOrUpdateCase;
      capturedProcedures = createdCase.procedures; // HACK TO ENSURE PROCEDURES ARE RETAINED
    }
    if (createdCase && isIncludeSets && (sets.length > 0 || parts.length > 0)) {
      sets = sets.map(
        ({
          set_id,
          loaner__c,
          quantity__c,
          is_preference_product__c,
          productSfid,
        }) => {
          const is_loaner = loaner__c
            ? typeof loaner__c === "boolean"
              ? loaner__c
              : JSON.parse(loaner__c)
            : false;
          return {
            id: is_loaner ? productSfid : set_id,
            type: is_loaner ? "loaner" : "consignment",
            quantity: quantity__c || 1,
            is_preference_product__c,
          };
        }
      );
      parts = parts.map(
        ({
          product_sfid,
          quantity__c,
          loaner__c,
          is_preference_product__c,
        }) => {
          return {
            product_sfid: product_sfid,
            quantity: quantity__c || 1,
            is_loaner: loaner__c,
            is_preference_product__c: is_preference_product__c,
          };
        }
      );

      const payload = {
        case__c: createdCase.sfId || null,
        externalId: createdCase.externalId || null,
        sets: sets || [],
        parts: parts || [],
        is_hospital_owned: createdCase.is_hospital_owned
          ? JSON.parse(createdCase.is_hospital_owned)
          : false,
        division: division || "",
        is_submit: false,
        case_current_status: status,
      };
    //   const result = await setCaseProducts(payload);
    //   if (result.data.setCaseProducts && result.data.setCaseProducts !== null)
    //     createdCase = result.data.setCaseProducts;
    // }

    const result = await setWebCaseProducts(payload);
    if (result.data.setWebCaseProducts && result.data.setWebCaseProducts !== null)
      createdCase = result.data.setWebCaseProducts;
  }

    if (
      createdCase &&
      isIncludeUsages &&
      (usages.length > 0 || additionalFees.length > 0)
    ) {
      const filteredUsages = usages.filter((part) =>
        part.lot_controlled__c ? !JSON.parse(part.lot_controlled__c) : true
      );

      const cloneUsages = filteredUsages.map((part) => {
        let transformed = {
          surgical_cases__r__external_id__c: createdCase.externalId || null,
          surgical_cases__c: createdCase.sfId || null,
          product__c: part.product_sfid,
          quantity__c: part.quantity__c || null,
          wasteds__c: false,
          side__c: part.side__c || "",
          usg_price__c: null,
          lot_controlled__c: false,
          ship_to__c: part.ship_to__c || "",
          replenish__c: false,
          procedure__c: part.procedure__c || "",
          price_override__c: null,
          expiry_date__c: null,
          serial_number__c: null,
          csr_usage_price__c: null,
          override_price__c: null,
          usg_total_amount__c: "0.0",
        };

        if (part.set_usages_id) {
          switch (typeof part.set_usages_id) {
            case "string":
              transformed.used_from__c = part.set_usages_id;
              break;
            case "object":
              transformed.inventory_product_system__c =
                part.set_usages_id.value.set_id;
              break;
          }
        }

        return transformed;
      });

      const procedures = createdCase.procedures;

      let surgeryCaseProcedures = procedures.map((procedure) => {
        const procedureLocal = filteredUsages.filter(
          (part) => part.procedure__c === procedure.sfid
        );

        let price = "0.0";
        if (
          procedureLocal &&
          procedureLocal.length > 0 &&
          procedureLocal[0].cap_price__c
        )
          price = procedureLocal[0].cap_price__c;

        return {
          casesid__r__external_id__c: createdCase.externalId || "",
          casesid__c: createdCase.sfId || "",
          procedureid__c: procedure.sfid || "",
          cap_price__c: price,
        };
      });

      surgeryCaseProcedures = uniq(surgeryCaseProcedures, "procedureid__c");
      //I-345034 No pricing should be clone
      /* additionalFees = [...additionalFees].map(({ value__c, name }) => {
        return {
          surgical_case__r__external_id__c: createdCase.externalId,
          surgical_case__c: createdCase.sfId || '',
          value__c: value__c || '',
          name: name || ''
        };
      });
*/
      const caseObjectPaylod = {
        patient_info__c: createdCase.patientId || "",
        status__c: "New",
        urgentreplenish__c: false,
      };

      const payloadUsage = {
        surgical_cases__r__external_id__c: createdCase.externalId,
        surgical_cases__c: createdCase.sfid || "",
        caseDetail: caseObjectPaylod,
        parts: cloneUsages || [],
        surgeryCaseProcedures: surgeryCaseProcedures || [],
        additionalFees: [],
        images: [],
      };

      const result = await setCasesUsage(payloadUsage);
      if (result.data.setCasesUsage && result.data.setCasesUsage !== null)
        createdCase = result.data.setCasesUsage;
    }
   
    createdCase.procedures = capturedProcedures; // HACK TO ENSURE PROCEDURES ARE RETAINED
    return createdCase;
  } catch (ex) {
    console.log(ex);
    return createdCase;
  }
}



export async function deleteAndUpdateFilter(id) {
  const mutation = gql`
    mutation deleteAndUpdateFilter($id: String) {
      deleteAndUpdateFilter(id: $id) {
        id
        user_sfid
        name
        definition {
          division
          filterBranch
          role
          filterSalesRep
          status
          procedure
          filterHospital
          filterSurgeon
          startDate
          endDate
          favorite
          filterSalesTeam
        }
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: { id },
  }).catch((e) => {
    console.log("catch error delete", e);
  });
}

export async function cloneCaseRecordHistory(comments) {
  const mutation = gql`
    mutation cloneCaseRecordHistory($comments: [CaseRecordHistoryInput]) {
      cloneCaseRecordHistory(comments: $comments)
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: {
      comments,
    },
  });
}

export function transformPrefencesToPayload(form, name, user) {
  const surgeonName =
    form &&
    form.surgeon &&
    form.surgeon.label &&
    form.surgeon.label.split("-").slice(1).join("-").trim();
  let preferenceName = "";
  if (surgeonName && form.procedure && form.procedure.label) {
    preferenceName = `${surgeonName}-${form.procedure.label}`;
  }
  const payload = {
    surgeon_perference: {
      name: name || preferenceName,
      hospitalid__c: form.hospital.value,
      branchid__c: form.branch_id || user.branchIds[0],
      contactid__c: form.surgeon.value,
      procedureid__c: form.procedure.value,
      nickname : form.nick_name__c,	
      surg_pref_comments__c: form.surg_pref_comments__c
    },
  };
  let parts = form.selectedParts.map((part) => {
    return {
      ipsid__c: null,
      product_system__c: null,
      productid__c: part.product_sfid,
      isloaner__c: part.is_loaner,
    };
  });
  const sets = form.selectedSets.map(({ id, type }) => ({
    ipsid__c: type === "consignment" ? id : null,
    product_system__c: type === "loaner" ? id : null,
    productid__c: null,
    isloaner__c: type === "consignment" ? false : true,
  }));

  payload.preference_details = [...parts, ...sets];
  return payload;
}

export const transformSurgPrefData = ({ preferences, preferencesSets }) => {
  let preferencesMap = new Map();
  for (const preference of preferences) {
    preference.sets = [];
    if (preference.external_id__c) {
      preferencesMap.set(preference.external_id__c, preference);
    } else {
      preferencesMap.set(preference.sfid, preference);
    }
  }
  for (const preferenceSet of preferencesSets) {
    if (preferenceSet.set_id !== null) {
      let preferenceObj;
      if (preferenceSet.surgeon_preference__r__external_id__c) {
        preferenceObj = preferencesMap.get(
          preferenceSet.surgeon_preference__r__external_id__c
        );
      } else {
        preferenceObj = preferencesMap.get(preferenceSet.surgeon_preference__c);
      }
      if (preferenceObj) {
        const alreadyPresentSet = preferenceObj.sets.find(
          (set) => set.set_id === preferenceSet.set_id
        );
        if (alreadyPresentSet && !alreadyPresentSet["isPrefItemsValid"]) {
          alreadyPresentSet["isPrefItemsValid"] =
            preferenceSet.isPrefItemsValid;
        } else if (!alreadyPresentSet) {
          preferenceObj.sets.push(preferenceSet);
        }
        preferenceObj["isactive__c"] = !preferenceObj.sets.some(
          (set) => set["isPrefItemsValid"] === false
        );
      }
    }
  }
  return preferences;
};

export function buildSurgnPreferencePayload(form, user, isTPRPreference) {
  const { combination, surgicalPreferenceObj, tprPreferenceObj } = form;
  const surgeonName =
    combination &&
    combination.surgeon &&
    combination.surgeon.surgeonName &&
    combination.surgeon.surgeonName.split("-").slice(1).join("-").trim();
  let preferenceName = "";
  if (surgeonName && combination.procedure) {
    preferenceName = `${surgeonName}-${combination.procedure.label}`;
  }
  preferenceName = isTPRPreference
    ? `TP-${preferenceName}`
    : `SP-${preferenceName}`;
  const payload = {
    surgeon_perference: {
      name: preferenceName,
      hospitalid__c: combination.hospital.hospitalId,
      branchid__c: combination.branch || user.branchIds[0],
      contactid__c: combination.surgeon.surgeonID,
      nick_name__c: combination.nickname,
      surg_pref_comments__c: isTPRPreference ? tprPreferenceObj.comments : surgicalPreferenceObj.comments
    },
  };

  if (isTPRPreference) {
    if (combination.procedure.label !== "Total Hip") {
      payload.surgeon_perference = {
        ...payload.surgeon_perference,
        procedure__c: combination.procedure.label,
        femoral_component__c: tprPreferenceObj.FemoralComponentValue || null,
        baseplate__c: tprPreferenceObj.BasePlateValue || null,
        insert_type__c: tprPreferenceObj.InsertTypeValue || null,
        version__c: tprPreferenceObj.version__c || null,
      };
    } else {
      payload.surgeon_perference = {
        ...payload.surgeon_perference,
        procedure__c: combination.procedure.label,
        approach__c: tprPreferenceObj.ApproachValue || null,
        acetabular_component__c:
          tprPreferenceObj.AcetabularComponentValue || null,
        femoral_component__c:
          tprPreferenceObj.FemoralComponentHipsValue || null,
        version__c: tprPreferenceObj.version__c || null,
      };
    }
    payload.preference_details = [];
  } else {
    payload.surgeon_perference = {
      ...payload.surgeon_perference,
      procedureid__c: combination.procedure.id,
    };
    const sets = surgicalPreferenceObj.loanerSets.map((item) => ({
      ipsid__c: null,
      product_system__c: item.set_id,
      productid__c: null,
      isloaner__c: true,
    }));
    payload.preference_details = sets;
  }

  return payload;
}

export function transformPrefencesToFrom(preferenceObj) {
  const {
    name,
    nickname,
    hospitalid__c,
    branchid__c,
    contactid__c,
    procedureid__c,
    external_id__c,
    sfid,
    hospitalName,
    surgeonName,
    branch,
    sets,
    parts,
    procedureName,
    surg_pref_comments__c,
    user_name,
    createdDate,
    storageExist,
    created_user_sfid,
  } = preferenceObj;

  const selectedSets = sets.map((item) => {
    const { set_id, set_name, loaner__c, kit_no__c, parts, isPrefItemsValid } =
      item;
    const is_loaner = loaner__c || "false";
    return {
      id: set_id,
      qty: "1",
      is_loaner: JSON.parse(is_loaner),
      type: !JSON.parse(is_loaner) ? "consignment" : "loaner",
      isPrefItemsValid: isPrefItemsValid,
      is_requested: JSON.parse(is_loaner) ? true : false,
      status__c: !JSON.parse(is_loaner)
        ? SETS_PARTS_STATUS.SHIPPED
        : SETS_PARTS_STATUS.REQUESTED,
      value: {
        set_id,
        set_name,
        label: set_name,
        kit_no__c,
        parts,
      },
    };
  });

  const form = {
    name,
    nickname,
    branchid__c,
    selectedSets,
    external_id__c,
    sfid,
    surg_pref_comments__c,
    user_name,
    createdDate,
    hospital: {
      label: hospitalName,
      value: hospitalid__c,
    },
    surgeon: {
      label: surgeonName,
      value: contactid__c,
    },
    procedure: {
      label: procedureName,
      value: procedureid__c,
    },
    storageExist,
    createdRep: created_user_sfid,
  };

  return form || {};
}

export async function createOrUpdatePrefrences(
  surgeonPerferences,
  external_id__c,
  sfid,
  filters
) {
  const mutation = gql`
    mutation createOrUpdatePrefrences(
      $SurgeonPerferenceInput: SurgeonPerferenceInput!
      $external_id__c: String
      $sfid: String
      $filters: PreferenceFilter
    ) {
      createOrUpdatePrefrences(
        payload: $SurgeonPerferenceInput
        external_id__c: $external_id__c
        sfid: $sfid
        filters: $filters
      ) {
        preferences {
          name
          nick_name__c
          hospitalid__c
          branchid__c
          contactid__c
          procedureid__c
          procedureName
          external_id__c
          sfid
          hospitalName
          surgeonName
          branch
          surg_pref_comments__c
          storageExist
          createdDate
          user_name
          storageExist
          created_user_sfid
        }
        preferencesSets {
          set_id
          set_name
          kit_no__c
          loaner__c
          productName
          type
          isPrefItemsValid
          surgeon_preference__r__external_id__c
          surgeon_preference__c
        }
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: {
      SurgeonPerferenceInput: surgeonPerferences,
      // branchId: branch_id,
      external_id__c,
      sfid,
      filters,
    },
  });
}

export async function setCaseDetails(variables) {
  const mutation = gql`
    mutation setCaseDetails($externalId: String, $caseSfid: String) {
      setCaseDetails(external_id__c: $externalId, sfid: $caseSfid) {
        print_data__c
        externalId
        sfId
        caseId
        status
        branch
        hospitalNoName
        surgeonName
        surgeonNoName
        storageExist
       
        procedures {
          sfid
          name
          cap_price__c
        }
        patientId
        urgentreplenish
        revision
        salesRep
        coveringRep
        capPrice
        surgeryDate
        deliveryDate
        deliveryOption
        shipTo
        shippingAddress
        billingToAddress
        shippingDate
        returnDate
        division
        internalComment
        hospitalName
        userRep
        coveringRepsSFIDs
        caseBranchId
        lastModifiedByUser
        lastModifiedDate
        mako
        adapt
        right
        left
        cancelReason
        cancelComment
        is_hospital_owned
        communityCase
        favoriteCase
        surgeonLastName
        hospitalid__c
        hospital_staffid__c
        hospital_location__c
        hospAddress
        hospSiteNo
        hospAddrSiteNo
        hospAddrPrimary
        QuickQuoteRationale
        QuickQuoteCode
        signatureHospitalRepName
        signatureSalesRepName
        externalComments
        pouploaded
        address_name
        address1__c
        address2__c
        city__c
        state_province__c
        postalcode__c
        ship_toaddress__r__external_id__c
        salesTeamsSFIDs
        signature_source__c
        covering_reps__c
        ship_toaddress__c
        usgreject_comments__c
        webops_id__c
        image {
          CaseExternalId
          signature
          attachmentname
          attachmentid
          imageurl
          thumbnailurl
        }
        externalComments
        sets {
          set_id
          set_name
          requested_set_name
          kit_no__c
          label
          oracle_sub_inventory_location
          loaner__c
          status__c
          quantity__c
          productName
          productSfid
          type
          is_preference_product__c
          setType
          parts {
            part_ipc_sfid
            product_sfid
            part_id
            part_name
            part_desc
            lot_serial_control_code__c
            unit
            lot_number
            serial
            expiration_date
            is_lot_controlled
            productOracleId
          }
        }
        parts {
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          lot_serial_control_code__c
          unit
          lot_number
          serial
          expiration_date
          loaner__c
          status__c
          quantity__c
          is_preference_product__c
        }
        usages {
          dns__c
          inventory__c
          aro_reference_number__c
          ship_priority__c
          ship_method__c
          sfid
          external_id__c
          case_sfid
          product_sfid
          part_ipc_sfid
          part_name
          part_desc
          quantity__c
          unit
          lot_number__c
          serialNumber
          unitNumber
          expirationDate
          wasted
          side__c
          usg_price__c
          set_usages_id
          lot_serial_control_code__c
          lot_controlled__c
          ship_to__c
          replenish__c
          procedure__c
          surgical_cases__r__external_id__c
          surgical_cases__c
          procedureName
          price_override__c
          cap_price__c
          procedureid__c
          inventory_product_system__c
          used_from__c
          aro_ship_to__r__external_id__c
          productOracleId
          contract__c
          csr_contract__c
          csr_usage_price__c
          csr_price_override__c
          override_price__c
          usg_total_amount__c
          contractComments
          contractEndDate
          contractStartDate
          contractDescription
          contractName
        }
        additionalFees {
          case_sfid
          surgical_case__r__external_id__c
          surgical_case__c
          value__c
          name
          external_id__c
        }
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables,
  });
}

export async function createOrUpdateAddress(variables) {
  const mutation = gql`
    mutation createOrUpdateAddress(
      $name: String
      $address: String
      $city: String
      $state: String
      $zip: String
      $external_id__c: String
    ) {
      createOrUpdateAddress(
        name: $name
        address: $address
        city: $city
        state: $state
        zip: $zip
        external_id__c: $external_id__c
      )
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables,
  });
}

export async function deletePrefrences(payload) {
  const mutation = gql`
    mutation deletePrefrences($external_id__c: String, $sfid: String) {
      deletePrefrences(external_id__c: $external_id__c, sfid: $sfid) {
        preferences {
          name
          nick_name__c
          hospitalid__c
          branchid__c
          contactid__c
          procedureid__c
          procedureName
          external_id__c
          sfid
          hospitalName
          surgeonName
          branch
          surg_pref_comments__c
          storageExist
          createdDate
          user_name
          storageExist
          created_user_sfid
        }
        preferencesSets {
          set_id
          set_name
          kit_no__c
          loaner__c
          productName
          type
          isPrefItemsValid
          surgeon_preference__r__external_id__c
          surgeon_preference__c
        }
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: payload,
  });
}

export const savePresetFilters = async (
  filterName,
  userSFID,
  filter,
  isdefault
) => {
  const mutation = gql`
    mutation createOrUpdateFilter(
      $id: String
      $name: String!
      $user_sfid: String!
      $definition: FilterDefinitionInput
      $isdefault: Boolean
    ) {
      createOrUpdateFilter(
        id: $id
        user_sfid: $user_sfid
        name: $name
        definition: $definition
        isdefault: $isdefault
      ) {
        id
        user_sfid
        name
        isdefault
        definition {
          division
          filterBranch
          role
          filterSalesRep
          status
          procedure
          filterHospital
          filterSurgeon
          startDate
          endDate
          favorite
          filterSalesTeam
        }
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: {
      name: filterName,
      user_sfid: userSFID,
      definition: filter,
      isdefault: isdefault,
    },
  });
};

export const deletePresetFilter = async (id) => {
  const mutation = gql`
    mutation deleteAndUpdateFilter($id: String) {
      deleteAndUpdateFilter(id: $id) {
        id
        user_sfid
        name
        definition {
          division
          filterBranch
          role
          filterSalesRep
          status
          procedure
          filterHospital
          filterSurgeon
          startDate
          endDate
          favorite
          filterSalesTeam
        }
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: { id },
  });
};

// Refresh Jwt
export const refreshJwt = async (refreshToken) => {
  const mutation = gql`
    mutation refreshJwt($refreshToken: String) {
      refreshJwt(refresh_token: $refreshToken) {
        access_token
        refresh_token
        mako_token
        as1_token
        dips_token
        expires_in
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: { refreshToken },
  });
};

export const notificationBanner = async (user_sfid, name, isChecked) => {
  const mutation = gql`
    mutation createOrUpdateNotificationBanner($user_sfid:String!, $name:String!, $isChecked:Boolean) {
      createOrUpdateNotificationBanner(user_sfid: $user_sfid, name: $name, isChecked: $isChecked) {
        user_sfid
        name
        isChecked
      }
    }
  `;
  return GraphQLClient.mutate({
    mutation,
    variables: { user_sfid: user_sfid, name: name, isChecked: isChecked },
  });
};

export const updateUploadedBy = async (user_sfid, name) => {
  const mutation = gql`
    mutation createOrUpdateUploadedBy($user_sfid: String!, $name: String!) {
      createOrUpdateUploadedBy(user_sfid: $user_sfid, name: $name) {
        user_sfid
        name
      }
    }
  `;

  return GraphQLClient.mutate({
    mutation,
    variables: {
      user_sfid: user_sfid,
      name: name,
    },
  });
};
export const updateMICS = async (payload) => {
  const mutation = gql`
  mutation createOrUpdateMics($id:String,$payload:CaseUpsertInputforMics){
    createOrUpdateMics(id:$id,payload:$payload){
      externalId
      sfId
      procedure_version__c
      tha_approach__c
      rob__c
      __typename
      }
    }
  `
  return GraphQLClient.mutate({
    mutation,
    variables: { id: "", payload },
  });
}

export const MAKOTraking = async (payload) => {
  const mutation = gql`
  mutation createOrUpdateMPSTracker($payload: MPSTracker){
    createOrUpdateMPSTracker(payload:$payload){
      externalId
      sfId
      coveringuser_present__c
      coveringuser_supporting__c
      sales_rep_present__c
      __typename
      }
    }
  `
  return GraphQLClient.mutate({
    mutation,
    variables: { payload },
  });
}

//Added to set tpr values in DB
export const setTPRDetails = async (payload) => {
  const mutation = gql`
  mutation setTreatmentPlanRequestDetails($payload:TPRDetailsInput){
    setTreatmentPlanRequestDetails(payload:$payload){
      case_sfid
      external_id
      tpr_name
      tpr_sfid
      tpr_external_id
      version
      approach
      acetabular_component
      femoral_component
      baseplate
      insert_type
      procedure
      isRevision
      __typename
      }
    }
  `
  return GraphQLClient.mutate({
    mutation,
    variables: { payload },
  });
}