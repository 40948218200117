import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const logoStyle = {
  width: "100%",
  // maxWidth : '500px' ,
  height: "auto",
  objectFit: "cover",
  objectPosition: "center",
  // display : 'flex',
  // alignItems : 'center',
  // justifyContent : 'center'
};

export default function RepsuiteSupport() {
  const [expandPanel, setExpandPanel] = useState(false);
  const [panel, setPanel] = useState(["panel0", "panel1"]);

  const handleExpanded = (expand, pan) => {
    console.log(expand, pan);
    console.log("test", panel.findIndex((i) => i === pan) < 0);
    if (expand || !expand) {
      if (panel.findIndex((i) => i === pan) < 0) {
        setPanel((prev) => [...prev, pan]);
      } else {
        setPanel((prev) => prev.filter((i) => i !== pan));
      }
    }
    setExpandPanel(expand);
  };

  console.log(panel);

  return (
    <Grid item sm={12} xs={12}>
      {/* <div className="NotificationProgress"><CircularProgress style={{ color: 'gold' }} disableShrink /> </div> */}
      <div>
        <Grid
          container
          spacing={1}
          xs={12}
          className="backgroundgreycolor borderbottom pagetitleHead"
          style={{ textAlign: "center" }}
        >
          <CardContent>
            <Typography>Support</Typography>
          </CardContent>
        </Grid>
        <div className="tprCaseDetailsPanel">
          <Accordion expanded={panel.find((i) => i === "panel0") || false}>
            <AccordionSummary
              style={{
                backgroundColor: "#eaeaea",
                fontWeight: "bold",
                fontSize: "14px",
                textAlign: "center",
              }}
              expandIcon={
                <ExpandMore
                  onClick={() => handleExpanded(!expandPanel, "panel0")}
                />
              }
              onClick={() => handleExpanded(!expandPanel, "panel0")}
            >
              <CardContent>
                <FontAwesomeIcon
                  style={{ marginInline: "1em", fontSize: "1em" }}
                  icon={faLink}
                />
                <a
                  href="https://fieldsystemssupport.atlassian.net/servicedesk/customer/portal/11/conversation"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#1071f0",
                    fontSize: "1rem",
                  }}
                >
                  RepSuite Support
                </a>
              </CardContent>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#fafafa" }}>
              <Typography variant="p" style={{}}>
                Use this link for RepSuite Support including: Access to another
                branch's CT scans, Sales Team changes, Usage Modifications, Edit
                Mako Tracking, Missing CT Scans, New Imaging Facility Setups,
                WebOps Error on RepSuite Case, General Support, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="tprCaseDetailsPanel">
          <Accordion expanded={panel.find((i) => i === "panel1") || false}>
            <AccordionSummary
              style={{
                backgroundColor: "#eaeaea",
                fontWeight: "bold",
                fontSize: "14px",
              }}
              expandIcon={
                <ExpandMore
                  onClick={() => handleExpanded(!expandPanel, "panel1")}
                />
              }
              onClick={() => handleExpanded(!expandPanel, "panel1")}
            >
              <CardContent>
                <FontAwesomeIcon
                  style={{
                    marginInline: "1em",
                    fontSize: "1em",
                  }}
                  icon={faLink}
                />
                <a
                  href="https://stryker-robotics.atlassian.net/servicedesk/customer/portal/11"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#1071f0",
                    fontSize: "1rem",
                  }}
                >
                  Mako Clinical Support
                </a>
              </CardContent>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#fafafa" }}>
              <Typography variant="p" style={{}}>
                Use this link for Mako Clinical Support including: Mako
                Application Support, Mako Laptop support, CT scan rejection
                support/issues, Sterilization Requests, etc.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Grid>
  );
}
